/* RectangularView.css */
.rectangular-view {
  width: 300px; /* Set the width of the rectangle */
  height: 200px; /* Set the height of the rectangle */
  background-color: #000000; /* Set the background color */
  border: 5px solid #008000; /* Set the border color and thickness */
  color: #fff; /* Set the text color */
  padding: 20px; /* Add padding to the content inside the rectangle */
  margin: 20px; /* Add margin to create space around the rectangle */
  
}